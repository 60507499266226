import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import validator from 'validator';
import { useSelector } from 'react-redux';

import {
  SPACING_120,
  SPACING_16,
  SPACING_32,
  SPACING_8,
} from '../../constants/spacing';

import { t, config } from '@formue-app/core';

import { Button } from '../buttons';
import { H1Small, Paragraph, ParagraphSmall } from '../texts';
import { ContentWrapper } from '../layout';
import { Input } from '../formElements/Input';
import { Checkbox } from '../formElements/Checkbox';
import { Select } from '../formElements/Select';
import { CenteredActivityIndicator } from '../common/ActivityIndicator';
import { BackButton } from '../common/BackButton';
import {
  paragraphSmallLineHeight,
  paragraphSmallSize,
} from '../../constants/text';

const StyledBackButton = styled(BackButton)`
  position: absolute;
  top: 79px;
  left: 40px;
`;

const StyledContentWrapper = styled(ContentWrapper)`
  flex-direction: column;
  align-items: center;
  padding-top: ${SPACING_120};
  padding-bottom: ${SPACING_120};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_16};
  width: 320px;
`;

const StyledButton = styled(Button)`
  margin-top: ${SPACING_8};
  width: 320px;
`;

const StyledCheckbox = styled(Checkbox)`
  width: 320px;
  align-items: flex-start;
`;

export const PersonalInfoRegistration = (props) => {
  const { user, setUser, onSubmit, onGoBack } = props;

  const loading = useSelector((state) => state.auth.loading);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [marketing, setMarketing] = useState(false);
  const [marketingPhone, setMarketingPhone] = useState(false);
  const [source, setSource] = useState(undefined);

  const [status, setValidateStatus] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
  });

  const onChangeEmail = (event) => {
    setValidateStatus({
      ...status,
      email: validator.isEmail(event.target.value),
    });
    setEmail(event.target.value);
  };

  const onChangePhone = (event) => {
    setValidateStatus({
      ...status,
      phone: validator.isMobilePhone(event.target.value, [
        'nb-NO',
        'sv-SE',
        'da-DK',
      ]),
    });
    setPhone(event.target.value);
  };

  const onChangeFirstName = (event) => {
    setValidateStatus({
      ...status,
      firstName: !validator.isEmpty(event.target.value),
    });
    setFirstName(event.target.value);
  };
  const onChangeLastName = (event) => {
    setValidateStatus({
      ...status,
      lastName: !validator.isEmpty(event.target.value),
    });
    setLastName(event.target.value);
  };

  const disabled = Object.values(status).some((item) => item === false);

  useEffect(() => {
    setUser({
      ...user,
      firstName,
      lastName,
      email,
      phone,
      source,
      marketing,
      marketingPhone,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    email,
    firstName,
    lastName,
    marketing,
    marketingPhone,
    phone,
    source,
    setUser,
  ]);

  // Values to be sendt to backend. Correspoinds to the values in SF.
  const sourcesValues = [
    'SelectSource',
    'Google',
    'Facebook',
    'LinkedIn',
    'Advertisement',
    'Event',
    'Referral',
    'Newspaper',
    'Other',
  ];

  return (
    <StyledContentWrapper position="center">
      <StyledBackButton onClick={onGoBack} />
      {loading ? (
        <CenteredActivityIndicator />
      ) : (
        <>
          <H1Small style={{ marginBottom: SPACING_16 }}>
            {t('login:registration:personalInfo:title')}
          </H1Small>
          <Paragraph style={{ marginBottom: SPACING_32 }}>
            {t('login:registration:personalInfo:body')}
          </Paragraph>
          <Form onSubmit={onSubmit}>
            <Input
              label={t('login:registration:personalInfo:firstName')}
              value={firstName}
              onChange={onChangeFirstName}
              popoutLabel={true}
              error={status.firstName}
            />
            <Input
              label={t('login:registration:personalInfo:lastName')}
              value={lastName}
              onChange={onChangeLastName}
              popoutLabel={true}
              error={status.lastName}
            />
            <Input
              label={t('login:registration:personalInfo:email')}
              value={email}
              onChange={onChangeEmail}
              popoutLabel={true}
              error={status.email}
            />
            <Input
              label={t('login:registration:personalInfo:phone')}
              value={phone}
              onChange={onChangePhone}
              popoutLabel={true}
              error={status.phone}
            />
            <Select
              label={t('login:registration:personalInfo:select')}
              popoutLabel={true}
              options={sourcesValues.map((key, index) => ({
                label: t(`login:registration:personalInfo:options:${key}`),
                value: key,
                disabled: index === 0,
                selected: index === 0,
              }))}
              onChange={(event) => setSource(event.target.value)}
            />
            <StyledCheckbox
              checked={marketing}
              onChange={() => setMarketing(!marketing)}
              label={t('login:registration:personalInfo:checkbox')}
              labelSize={paragraphSmallSize}
              labelLineHeight={paragraphSmallLineHeight}
            />
            {config.countryCode === 'dk' ? (
              <StyledCheckbox
                checked={marketingPhone}
                onChange={() => setMarketingPhone(!marketingPhone)}
                label={t('login:registration:personalInfo:phoneConsent')}
                labelSize={paragraphSmallSize}
                labelLineHeight={paragraphSmallLineHeight}
              />
            ) : null}
            <StyledButton
              disabled={disabled}
              buttonstyle="filled"
              onClick={onSubmit}
            >
              {t('login:registration:personalInfo:nextButton')}
            </StyledButton>
          </Form>

          <ParagraphSmall style={{ marginTop: SPACING_32 }}>
            <a
              target="_blank"
              rel="noreferrer"
              href={t('menu:privacyPolicy:link')}
            >
              {t('login:registration:personalInfo:gdpr')}
            </a>
          </ParagraphSmall>
        </>
      )}
    </StyledContentWrapper>
  );
};
