import React from 'react';
import styled from 'styled-components';

import { percentage, selectors, t, useResource } from '@formue-app/core';

import { BaseSection } from '../../common/BaseSection';
import { SPACING_24, SPACING_8 } from '../../../constants/spacing';
import { H2, ParagraphSmall } from '../../texts';
import { StretchedHorizontalBarChart } from '../../graphs/StretchedHorizontalBarChart';
import { DotIcon } from '../../common/DotIcon';
import { accent } from '../../../constants/colors';
import { useSelector } from 'react-redux';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
`;

const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${SPACING_8};
`;

const {
  entities: {
    portfolios: { portfolioTotalSelector },
  },
} = selectors;

export const PeriodicBenchmark = (props) => {
  const loading = useResource(['MARKETVALUES/INDEX', 'PORTFOLIOS/INDEX']);

  const portfolioTotal = useSelector(portfolioTotalSelector);

  const benchmarkData = [
    {
      label: (
        <LabelWrapper>
          <DotIcon radius={20} color={accent.ocean170} />
          <ParagraphSmall>Formue portfolio</ParagraphSmall>
        </LabelWrapper>
      ),
      value: portfolioTotal?.twr,
      color: accent.ocean230,
    },
    {
      label: (
        <LabelWrapper>
          <DotIcon radius={20} color={accent.ocean190} />
          <ParagraphSmall>Syntetisk index</ParagraphSmall>
        </LabelWrapper>
      ),
      value: portfolioTotal?.twr * Math.random(),
      color: accent.ocean230,
    },
    {
      label: (
        <LabelWrapper>
          <DotIcon radius={20} color={accent.ocean190} />
          <ParagraphSmall>Lakalt marked</ParagraphSmall>
        </LabelWrapper>
      ),
      value: portfolioTotal?.twr * Math.random(),
      color: accent.ocean230,
    },
    {
      label: (
        <LabelWrapper>
          <DotIcon radius={20} color={accent.ocean190} />
          <ParagraphSmall>MSCI World Index</ParagraphSmall>
        </LabelWrapper>
      ),
      value: portfolioTotal?.twr * Math.random(),
      color: accent.ocean230,
    },
    {
      label: (
        <LabelWrapper>
          <DotIcon radius={20} color={accent.ocean190} />
          <ParagraphSmall>Global bonds</ParagraphSmall>
        </LabelWrapper>
      ),
      value: portfolioTotal?.twr * Math.random(),
      color: accent.ocean230,
    },
  ];

  return (
    <BaseSection
      columns={6}
      style={{ display: 'flex', flexDirection: 'column', gap: SPACING_24 }}
    >
      <TextWrapper>
        <ParagraphSmall>
          {t('portfolio:periodicReporting:benchmark')}
        </ParagraphSmall>
        <H2>{percentage(portfolioTotal?.twr * 100, 1)}</H2>
      </TextWrapper>
      {!portfolioTotal || loading ? (
        <CenteredActivityIndicator />
      ) : (
        <StretchedHorizontalBarChart
          barWidth={30}
          data={benchmarkData}
          formatValue={(value) => percentage(value * 100, 1)}
        />
      )}
    </BaseSection>
  );
};
