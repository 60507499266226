import React, { useRef } from 'react';
import styled from 'styled-components';
import { VictoryArea } from 'victory';
import { maxBy, minBy } from 'lodash';

import { accent } from '../../constants/colors';

const ChartWrapper = styled.div`
  flex: 1;
  transition: opacity 0.3s ease-in-out;
`;

export const SimpleLineChart = (props) => {
  const { data } = props;
  const wrapperRef = useRef(null);

  const maxValue = maxBy(data, 'value');
  const minValue = minBy(data, 'value');

  if (!minValue || !maxValue) return null;

  const y0 = minValue?.value;

  return (
    <ChartWrapper ref={wrapperRef}>
      <VictoryArea
        interpolation="monotoneX"
        y="value"
        y0={(d) => y0}
        data={data}
        padding={{ bottom: 0, left: 0 }}
        domainPadding={{ x: [0, 0], y: 10 }}
        animate={false}
        standalone={true}
        style={{
          data: {
            stroke: accent.ocean490,
            strokeWidth: 3,
            fill: accent.ocean210,
          },
        }}
      />
    </ChartWrapper>
  );
};
