import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, useLocation } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';

import {
  config,
  selectors,
  getFlagsSelector,
  actions as coreActions,
} from '@formue-app/core';

import { useRoutes } from './config/routes';
import { usePageTracking } from './services/tracking/usePageTracking';

import { RouteWithSubRoutes } from './components/router';
import { LeadRegistrationModal } from './components/lead/LeadRegistrationModal';
import { PodcastPopover } from './components/popover/PodcastPopover';

import { GlobalFonts } from './fonts';
import { ResetStyles } from './reset';

import { actions as appActions } from './store/app';
import { actions as authActions } from './store/auth';
import { actions as webAuthnActions } from './store/webauthn';

const { currentUserSelector } = selectors.ui.user;
const { user: userActions } = coreActions.ui;

const App = () => {
  document.title = config.title;
  const routes = useRoutes();
  const location = useLocation();
  const dispatch = useDispatch();
  const [initialized, setInitialized] = useState(false);
  usePageTracking();
  const { pathname, search } = location;
  const user = useSelector(currentUserSelector);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  // Hack to make text update to correct language
  // this triggers a re-render when the language changes
  const { language: chosenLanguage } = useSelector((state) => state.ui.user);

  /**
   * If there is a `access_token` hash param in the URL, we wan't to use the value of that as the
   * token and store that as we would have if the user authenticated through one of the regular
   * login routes
   */
  const hashParams = new URLSearchParams(document.location.hash.substring(1));
  const accessToken = hashParams.get('access_token');

  // Load webauthn stuff on app init
  dispatch(webAuthnActions.load());

  if (accessToken) {
    dispatch(authActions.setAccessToken(accessToken));
    dispatch(authActions.finishAuthentication(`${pathname}${search}`));
  }

  /**
   * If we pass in a language query param we want to override any stored preferences for
   * language and use the given language instead
   */
  const queryParams = new URLSearchParams(
    document.location.search.substring(1)
  );
  const language = queryParams.get('language');
  if (language) {
    dispatch(userActions.setLanguage(language));
  }

  /**
   * If we pass in an impersonationId query param we store that in sessionStorage
   * so that after we are authenticated we will automatically be impersonating
   * this user without the need for the advisor to select the client from the list
   *
   * This is used in a few instances where we automatically log users in, either
   * via the Azure SSO solution, or using the #access_token=foobar trick.
   */
  const impersonationId = queryParams.get('impersonationId');
  if (impersonationId) {
    window.sessionStorage.setItem('impersonationId', impersonationId);
  }

  /**
   * Configure the auth timeout, for regular users we want to use the configured
   * value, but for employees we check the expiration of the token and use that
   * as the idle timeout. Ensuring that employees stayes logged in for as long
   * as the token is valid.
   */
  const { exp, isEmployee } = useSelector((state) => state.auth);
  let authTimeout = config.auth.timeout;
  if (isEmployee && exp) {
    // find number of ms until expiration of token and use that as auth timeout
    const now = Date.now();
    const msLeftUntilTokenExpiration = exp * 1000 - now;
    authTimeout = msLeftUntilTokenExpiration;
  }

  const { isLead } = useSelector(getFlagsSelector);
  const [registrationModalIsOpen, setRegistrationModalIsOpen] = useState(false);

  const handleOnIdle = () => {
    dispatch(
      authActions.logout({
        state: {
          error: 'SessionTimeout',
        },
      })
    );
  };

  useIdleTimer({
    timeout: authTimeout,
    onIdle: handleOnIdle,
  });

  /**
   * Determine if we should open the lead registration modal
   */
  useEffect(() => {
    if (!user || !isLead) return;
    const { email } = user;

    if (!email) {
      setRegistrationModalIsOpen(true);
    }
  }, [user, isLead]);

  // In the case where we are in the middle of the authentication flow
  // we don't want to re-initialize the app, that would disrupt the state
  // and cause problems with the re-authentication flow.
  useEffect(() => {
    if (!initialized) {
      const publicPaths = routes.filter((r) => r.public).map((r) => r.path);

      if (!publicPaths.includes(pathname)) {
        dispatch(appActions.init());
        setInitialized(true);
      }
    }
  }, [dispatch, routes, initialized, pathname]);

  return (
    <Suspense fallback={<h1>Initializing...</h1>}>
      <ResetStyles />
      <GlobalFonts />
      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}
      </Switch>
      { isAuthenticated ? <PodcastPopover /> : null }
      <LeadRegistrationModal
        isOpen={registrationModalIsOpen}
        onRequestClose={() => setRegistrationModalIsOpen(false)}
      />
    </Suspense>
  );
};

export default App;
