import React from 'react';
import MediaQuery from 'react-responsive';

import { layoutMaxWitdh } from './grid';
import { createGlobalStyle } from 'styled-components';

export const tabletWidth = 768;
export const desktopWidth = 1024;
export const desktopWidthMax = layoutMaxWitdh;

export const printCondition = 'only print';
export const notPrintCondition = 'not print';
export const mobileCondition = `(max-width: ${
  tabletWidth - 1
}px) and not print`;
export const notDesktopCondition = `(max-width: ${desktopWidth}px)`;
export const notMobileCondition = `(min-width: ${tabletWidth}px)`;
export const tabletCondition = `(min-width: ${tabletWidth}px) and (max-width: ${desktopWidth}px)`;
export const desktopCondition = `(min-width: ${desktopWidth + 1}px)`;
export const desktopMaxCondition = `(max-width: ${desktopWidthMax}px)`;

export const NotMobileWrapper = (props) => (
  <MediaQuery query={notMobileCondition}>{props.children}</MediaQuery>
);

export const DesktopWrapper = (props) => (
  <MediaQuery query={desktopCondition}>{props.children}</MediaQuery>
);

export const TabletWrapper = (props) => (
  <MediaQuery query={tabletCondition}>{props.children}</MediaQuery>
);

export const NotDesktopWrapper = (props) => (
  <MediaQuery query={notDesktopCondition}>{props.children}</MediaQuery>
);

export const MobileWrapper = (props) => (
  <MediaQuery query={mobileCondition}>{props.children}</MediaQuery>
);

export const PrintWrapper = ({ children }) => {
  const childrenArray = Array.isArray(children) ? children : [children];

  return childrenArray.filter(Boolean).map((child) => {
    const className = `printOnly ${child.props?.className || ''}`;

    const props = {
      ...child.props,
      className,
    };

    const updatedChildren = React.cloneElement(child, props);

    return updatedChildren;
  });
};

// export const LandscapeOverrideStyled = createGlobalStyle`
//   @page {
//       size: landscape;
//     }
// `;
