import React from 'react';
import styled, { css, keyframes } from 'styled-components';

import { accent } from '../../../constants/colors';
import { BORDER_RADIUS_SMALL } from '../../../constants/spacing';
import { easeInOut, longAnimationTime } from '../../../constants/animation';
import {
  mobileCondition,
  notDesktopCondition,
  printCondition,
} from '../../../constants/media';

import limitBorderUrl from '../../../assets/icons/portfolio/strategy-limit-border.svg';
import limitBorderLightUrl from '../../../assets/icons/portfolio/strategy-limit-border-light.svg';
const StyledBar = styled.div`
  height: 16px;
  width: 100%;
  background-color: ${(props) =>
    props.barColor ? props.barColor : accent.sand1};

  @media ${notDesktopCondition} {
    margin-bottom: 0px;
  }

  @media ${mobileCondition} {
    height: 10px;
  }
`;

const limit = (props) => keyframes`
  from {
    width: 0%;
    left: 0%;
  }

  to {
    width: ${Math.min(props.width, 100 - props.limitStart)}%;
    left: ${props.limitStart}%;
  }
`;

const Limit = styled.div`
  height: 100%;
  background: none;
  position: relative;
  width: ${(props) => Math.min(props.width, 100 - props.limitStart)}%;
  left: ${(props) => props.limitStart}%;
  z-index: 2;
  border-width: 2px;
  border-style: solid;
  border-image-source: url(${(props) =>
    props.light ? limitBorderLightUrl : limitBorderUrl});
  border-image-slice: 8;
  border-image-width: 8px;
  border-image-repeat: round;

  ${(props) =>
    props.animate &&
    css`
      width: 0%;
      left: 0%;
      animation: ${(props) => limit(props)} ${longAnimationTime} ${easeInOut}
        forwards;
      animation-delay: ${(props) => props.customAnimationDelay}s;
    `}

  &:after {
    content: '';
    height: 24px;
    width: 2px;
    background: ${(props) => (props.light ? accent.velvet250 : 'black')};
    border-radius: 1px;
    top: -6px;
    left: ${(props) => props.strategyPosition}%;
    position: relative;
    display: block;
    translate: -1px;

    @media ${mobileCondition} {
      height: 18px;
    }
  }
`;

const slide = (props) => keyframes`
  0% {
    width: 0%;
    opacity: 0;
  }

  10% {
    opacity: 1;
  }

  100% {
    opacity: 1;
    width: ${props.market}%;
  }
`;

const Current = styled.div`
  width: ${(props) => props.market}%;
  height: 16px;
  top: -16px;
  background-color: ${(props) => props.barColor};
  position: relative;
  border-radius: ${BORDER_RADIUS_SMALL};

  ${(props) =>
    props.animate &&
    css`
      width: 0%;
      animation: ${(props) => slide(props)} ${longAnimationTime} ${easeInOut}
        forwards;
      animation-delay: ${(props) => props.customAnimationDelay}s;
    `};

  @media ${mobileCondition} {
    height: 10px;
    top: -10px;
  }
`;

export const Bar = (props) => {
  const {
    max,
    min,
    strategy,
    market,
    index = 0,
    customAnimationDelay = 0,
    runAnimation = true,
    barInsideColor = accent.velvet3,
    barOutsideColor = accent.velvet2,
    ...rest
  } = props;

  const limitWidth = max - min;
  const isInside = market >= min && market <= max;

  const barColor = isInside ? barInsideColor : barOutsideColor;

  return (
    <StyledBar {...rest} className="printBackground">
      <Limit
        animate={runAnimation}
        customAnimationDelay={customAnimationDelay + 0.2 * index}
        strategyPosition={((strategy - min) / limitWidth) * 100}
        width={limitWidth}
        limitStart={min}
        {...rest}
      />
      <Current
        animate={runAnimation}
        customAnimationDelay={customAnimationDelay + 0.3 + 0.2 * index}
        market={market}
        index={index}
        barColor={barColor}
        className="printBackground"
      />
    </StyledBar>
  );
};
