import {
  t,
  useWealthPlan,
  selectors,
  getFlagsSelector,
} from '@formue-app/core';

import { unresolvedTasksSelector } from '../store/ui/todo/selectors';
import { extendedReportingRoutes } from './extendedReporting';

import {
  SubMenuPage,
  AuthCallbackPage,
  AuthSsoPage,
  LoginPage,
  HomePage,
  WealthPlanPage,
  InvestmentProfilePage,
  InvestmentStrategiesPage,
  InvestmentStrategyPage,
  InvestmentSimulatorPage,
  StrategiesPage,
  ExpertInputPage,
  FamilyAndOwnershipPage,
  GoalsPage,
  PortfolioPage,
  PortfolioOverviewPage,
  PortfolioStrategyPage,
  PortfolioConstructionPage,
  HoldingsPage,
  HoldingsOverviewPage,
  FundsPage,
  TransactionsPage,
  DocumentsPage,
  TodoPage,
  TodoItemPage,
  AccountTeamPage,
  ContactPage,
  WealthBalancePage,
  ClientsPage,
  TaxInfoGeneralBasePage,
  TaxReportPageBasePage,
  NotFoundPage,
  FactSheetsPage,
  LensesPage,
  EsgPage,
  SustainabilityPage,
  GeographyPage,
  SectorsPage,
  CostReportPage,
  ProfileOverviewPage,
  SelfDeclarationPage,
  FundsListPage,
  CompaniesListPage,
  AcccountSettingsPage,
  PresentationPage,
  EventsPage,
  EventPage,
  WebinarPage,
  WebinarsPage,
  PodcastPage,
  AnnualReviewReportPage,
  StrategyDetailsPage,
  StrategySimulatorPage,
  ExtendedReportingPage,
  ExtendedReportingOverviewPage,
  AdvisorsCorner,
  AdvisorsCornerOverview,
  StrategyDetailsPreferencesPage,
  StrategyDetailsProductsPage,
  StrategyDetailsCostPage,
  StrategyDetailsAssetAllocationPage,
  StrategyDetailsImplementationPage,
  StrategyDetailsCommentsPage,
  ReportingPage,
  PeriodicReportPage,
  RegistrationPage,
} from '../pages';
import { accent } from '../constants/colors';
import { useSelector } from 'react-redux';

const { operatingCountrySelector } = selectors.auth;

export const useRoutes = () => {
  const showWealthPlan = useWealthPlan();
  const operatingCountry = useSelector(operatingCountrySelector);

  const isImpersonating = useSelector((state) => state.ui.user.isImpersonating);
  const isEmployee = useSelector((state) => state.auth.isEmployee);

  const flags = useSelector(getFlagsSelector);
  const { features } = flags;

  // prettier-ignore
  const routes = [
    { path: '/auth/callback', component: AuthCallbackPage, exact: true, public: true, showInMenu: false },
    { path: '/auth/sso', component: AuthSsoPage, exact: true, public: true, showInMenu: false },
    { path: '/login', component: LoginPage, exact: true, public: true, showInMenu: false },
    { path: '/registration', component: RegistrationPage, public: true, exact: true, showInMenu: false },
    { path: '/', component: HomePage, title: t('home:title'), exact: true, icon: 'House' },
    { path: '/events', component: EventsPage, title: 'Events', exact: true,  icon: 'Event', showInMenu: false, },
    { path: '/events/:id', component: EventPage, title: 'Event', exact: true,  icon: 'Event', showInMenu: false },
    { path: '/webinars', component: WebinarsPage, title: 'Webinar', exact: true,  icon: 'Art', showInMenu: false },
    { path: '/webinars/:id', component: WebinarPage, title: 'Webinar', exact: true,  icon: 'Art', showInMenu: false },
    { path: '/podcasts', component: PodcastPage, title: 'Podcast', exact: true,  icon: 'Event', showInMenu: false },
    { path: '/todo', component: TodoPage, title: t('todo:title'), exact: true, icon: 'To Do', selector: unresolvedTasksSelector },
    { path: '/todo/:type/:id', component: TodoItemPage, showInMenu: false },
    { path: '/wealth-plan', component: SubMenuPage, title: t('menu:wealthPlanTitle'), showInMenu: showWealthPlan, icon: 'Strategy', routes: [
        { path: '/wealth-plan', component: WealthPlanPage, title: t('globals:overview'), exact: true },
        { path: '/wealth-plan/goals', component: GoalsPage, showMobileTitle: false, inverted: true, title: t('wealthPlan:goals:title'), exact: true },
        { path: '/wealth-plan/family-and-ownership', component: FamilyAndOwnershipPage, showMobileTitle: false, inverted: true, title: t('wealthPlan:familyAndOwnership:title'), exact: true },
        { path: '/wealth-plan/wealth-balance', component: WealthBalancePage, showMobileTitle: false, inverted: true, title: t('wealthBalance:title'), exact: true },
        { path: '/wealth-plan/investment-profile', component: InvestmentProfilePage, showMobileTitle: false, inverted: true, title: t('wealthPlan:investmentProfile:title'), exact: true },
        {
          path: '/wealth-plan/investment-strategies', component: InvestmentStrategiesPage, showMobileTitle: false, title: t('wealthPlan:investmentStrategy:title'), routes: [
            { path: '/wealth-plan/investment-strategies/strategies', component: StrategiesPage, showMobileTitle: false, inverted: true, title: t('wealthPlan:investmentStrategy:navigation:title'), exact: true },
            { path: '/wealth-plan/investment-strategies/investment-simulator', component: InvestmentSimulatorPage, showMobileTitle: false, inverted: true, title: t('wealthPlan:investmentSimulator:title'), exact: true },
          ].filter((r) => (!r.disabled))
        },
        { path: '/wealth-plan/expert-input', component: ExpertInputPage, showMobileTitle: false, inverted: true, title: t('wealthPlan:expertAdvice:title'), exact: true },
      ]
    },
    { path: '/investment-strategy/:id', component: InvestmentStrategyPage, showInMenu: false },
    {
      path: '/portfolio', component: PortfolioPage, title: t('portfolio:title'), icon: 'Investment', routes: [
        { path: '/portfolio', component: PortfolioOverviewPage, title: t('globals:overview'), exact: true },
        { path: '/portfolio/holdings', component: HoldingsPage, title: t('portfolio:holdings'), routes: [
            { path: '/portfolio/holdings', component: HoldingsOverviewPage, title: t('portfolio:holdings'), exact: true },
            { path: '/portfolio/holdings/securities', component: FundsPage, title: t('portfolio:holdings'), exact: true, hideFilter: true }
          ]
        },
        { path: '/portfolio/transactions', component: TransactionsPage, title: t('portfolio:transactions'), exact: true },
        { path: '/portfolio/strategy', component: PortfolioStrategyPage, title: t('portfolio:strategy'), exact: true, showInMenu: true },
        {
          path: '/portfolio/lenses', component: LensesPage, title: t('portfolio:lenses'), routes: [
            { path: '/portfolio/lenses/portfolio-construction', component: PortfolioConstructionPage, title: t('portfolio:portfolioConstruction'), exact: true, color: accent.velvet4 },
            { path: '/portfolio/lenses/sustainability', component: SustainabilityPage, title: t('esg:title'), exact: true, color: accent.moss4 },
            { path: '/portfolio/lenses/geography', component: GeographyPage, title: t('portfolio:geography'), exact: true, color: accent.ocean4},
            { path: '/portfolio/lenses/sectors', component: SectorsPage,title: t('portfolio:sectors'), exact: true, color: accent.raspberry4 },
          ]
        },
        { path: '/portfolio/factsheets', component: FactSheetsPage, title: t('portfolio:factsheets'), exact: true, hideFilter: true },
        { path: '/portfolio/tax/2023', component: TaxInfoGeneralBasePage, title: t('portfolio:tax'), icon: 'Document Multi', exact: true, showInMenu: Boolean(operatingCountry !== 'dk'), hideFilter: true },
        { path: '/portfolio/tax/:year', component: TaxInfoGeneralBasePage, title: t('portfolio:tax'), icon: 'Document Multi', exact: true, showInMenu: false, hideFilter: true },
        { path: '/portfolio/tax/declaration/:year', component: TaxReportPageBasePage, icon: 'Document Multi', exact: true, showInMenu: false, hideFilter: true },
        { path: '/portfolio/cost', component: CostReportPage, title: t('portfolio:cost'), exact: true, showInMenu: true, hideFilter: true },
        { path: '/portfolio/reporting', component: ReportingPage, title: t('portfolio:reporting:title'), routes: [
            { path: '/portfolio/reporting/periodic', component: PeriodicReportPage, title: t('portfolio:periodicReporting:title'), icon: 'Time', exact: true, showInMenu: true, color: accent.ocean4, disabled: !features.portfolioReporting, hideFilter: true },
            { path: '/portfolio/reporting/tax/2023', component: TaxInfoGeneralBasePage, title: t('portfolio:tax'), icon: 'Taxes', exact: true, color: accent.ocean4, disabled: Boolean(operatingCountry === 'dk'), hideFilter: true },
            { path: '/portfolio/reporting/tax/:year', component: TaxInfoGeneralBasePage, title: t('portfolio:tax'), icon: 'Taxes', exact: true, color: accent.ocean4, showInMenu: false, hideFilter: true },
            { path: '/portfolio/reporting/tax/declaration/:year', component: TaxReportPageBasePage, icon: 'Taxes', exact: true, color: accent.ocean4, showInMenu: false, hideFilter: true },
            { path: '/portfolio/reporting/cost', component: CostReportPage, title: t('portfolio:cost'), icon: 'Transparency', exact: true, color: accent.ocean4, hideFilter: true },
          ].filter((r) => (!r.disabled))
        },
      ]
    },
    { path: '/extended-reporting', component: ExtendedReportingPage, title: t('portfolio:extendedReporting:overview'), showInMenu: false, routes: [
      { path: '/extended-reporting', component: ExtendedReportingOverviewPage, title: t('portfolio:extendedReporting:overview'), exact: true },
      ...extendedReportingRoutes
    ]},
    { path: '/list/funds-list', component: FundsListPage, exact: true, showInMenu: false },
    { path: '/list/companies-list', component: CompaniesListPage, exact: true, showInMenu: false },
    { path: '/documents', component: DocumentsPage, title: t('documents:title'), icon: 'Document Single', exact: true },
    { path: '/profile', component: ProfileOverviewPage, title: t('menu:profile'), icon: 'Individual', exact: true, showInMenu: false },
    { path: '/profile/self-declaration', component: SelfDeclarationPage, title: t('profile:selfDeclaration:title'), exact: true, showInMenu: false },
    { path: '/profile/accounts/:id', component: AcccountSettingsPage, icon: 'Individual', exact: true, showInMenu: false },

    { path: '/account-team', component: AccountTeamPage, title: t('menu:menuItems:clientTeam'), icon: 'Team', showInMenu: false, exact: true },
    { path: '/contact', component: ContactPage, title: t('menu:contact'), showInMenu: false, exact: true },
    { path: '/report/:id', component: AnnualReviewReportPage, showInMenu: false, exact: true },

    // 404 handler
    { path: '*', component: NotFoundPage, showInMenu: false }
  ]

  // prettier-ignore
  const advisorRoutes = [
    { path: '/advisor', component: AdvisorsCorner, title: t('menu:menuItems:advisor'), icon: 'Decision', showInMenu: false, showInAdvisorMenu: true, routes: [
      { path: '/advisor', component: AdvisorsCornerOverview, title: t('menu:menuItems:advisor'), icon: 'Decision', exact: true, showInMenu: isImpersonating },
      { path: '/advisor/presentation/:id', component: PresentationPage, showInMenu: false, exact: true },
      { path: '/advisor/clients', component: ClientsPage, title: t('impersonation:title'), icon: 'Search', showInMenu: true, exact: true },
      { path: '/advisor/strategy-simulator', component: StrategySimulatorPage, title: t('advisor:strategySimulator:title'), showInMenu: false, exact: true },
      { path: '/advisor/strategy-simulator/details/:id', component: StrategyDetailsPage, showInMenu: false, routes: [
        { path: '/advisor/strategy-simulator/details/:id', component: StrategyDetailsAssetAllocationPage, title: t('advisor:strategySimulator:assetAllocation:title'), exact: true },
        { path: '/advisor/strategy-simulator/details/:id/preferences', component: StrategyDetailsPreferencesPage, title: t('advisor:strategySimulator:preferences:title'), exact: true },
        { path: '/advisor/strategy-simulator/details/:id/product', component: StrategyDetailsProductsPage, title: t('advisor:strategySimulator:product:title'), exact: true },
        { path: '/advisor/strategy-simulator/details/:id/implementation', component: StrategyDetailsImplementationPage, title: t('advisor:strategySimulator:implementation:title'), exact: true },
        { path: '/advisor/strategy-simulator/details/:id/comments', component: StrategyDetailsCommentsPage, title: t('advisor:strategySimulator:comments:title'), exact: true },
        { path: '/advisor/strategy-simulator/details/:id/cost', component: StrategyDetailsCostPage, title: t('advisor:strategySimulator:cost:title'), exact: true, showInMenu: true },
      ]},
      ]
    },
  ];

  if (isEmployee) {
    return [...advisorRoutes, ...routes];
  } else {
    return routes;
  }
};
