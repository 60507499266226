import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  formatPeriodLabel,
  selectors,
  t,
  config,
  usePortfolioExternalReportingFilter,
  usePortfolioInvestmentAccountsFilter,
  usePortfolioRelationshipFilter,
  usePortfolioTaxClassFilter,
  useStartegyOptions,
} from '@formue-app/core';

import { GridElement } from '../../layout';
import { H3 } from '../../texts';

const {
  entities: {
    accounts: { allAccountsSelector },
  },
  ui: {
    portfolio: { activeStrategySelector },
  },
} = selectors;

const activeStrategyAccountsName = (strategyAccounts, accounts) =>
  strategyAccounts.map(
    (id) => accounts.find((account) => account.id === id)?.name
  );

export const PeriodicReportingPrintHeader = (props) => {
  const { ...rest } = props;
  const [activeStrategy, setActiveStrategy] = useState(null);

  const { period, startDate, endDate } = useSelector(
    (state) => state.ui.portfolio.filters
  );

  const activeStrategyId = useSelector(activeStrategySelector);
  const accounts = useSelector(allAccountsSelector);
  const allStrategies = useStartegyOptions();

  useEffect(() => {
    setActiveStrategy(
      allStrategies.find((strategy) => strategy.id === activeStrategyId)
    );
  }, [activeStrategyId, allStrategies]);

  const externalreportingFilter = usePortfolioExternalReportingFilter();
  const relationshipFilter = usePortfolioRelationshipFilter();
  const accountsFilter = usePortfolioInvestmentAccountsFilter();
  const taxClassFilter = usePortfolioTaxClassFilter();

  const selectedRelationships = relationshipFilter.filter(
    (item) => item.selected
  );
  const selectedAccounts = accountsFilter.filter((item) => item.selected);
  const selectedTaxClass = taxClassFilter.filter((item) => item.selected);
  const selectedExternalReporting = externalreportingFilter.filter(
    (item) => item.selected
  );

  return (
    <GridElement columns={12} {...rest}>
      <H3>{`${t('filters:timeSpanTitle')}: ${formatPeriodLabel(
        period,
        startDate,
        endDate,
        true
      )}`}</H3>
      {/* <H3>{activeStrategyAccountsName(activeStrategy.accounts, accounts)}</H3> */}
      {selectedRelationships.length ? (
        <H3>
          {`${t('filters:relationshipsTitle')}: ${selectedRelationships
            .map((item) => item.name)
            .join(', ')}`}
        </H3>
      ) : null}
      {selectedAccounts.length ? (
        <H3>
          {`${t('filters:accountTitle')}: ${selectedAccounts
            .map((item) => item.name)
            .join(', ')}`}
        </H3>
      ) : null}
      {selectedTaxClass.length ? (
        <H3>
          {`${t('filters:taxClassTitle')}: ${selectedTaxClass
            .map((item) => item.name)
            .join(', ')}`}
        </H3>
      ) : null}
      {selectedExternalReporting.length ? (
        <H3>
          {`${t('filters:accountTitle')}: ${selectedExternalReporting
            .map((item) =>
              t(`filters:${item.name}`, {
                companyName: config.title,
              })
            )
            .join(', ')}`}
        </H3>
      ) : null}
    </GridElement>
  );
};
