import React from 'react';
import styled from 'styled-components';

import {
  formatBigMoney,
  usePortfolioChartData,
  useResource,
  selectors,
  t,
} from '@formue-app/core';

import { BaseSection } from '../../common/BaseSection';
import { SPACING_24, SPACING_8 } from '../../../constants/spacing';
import { H2, ParagraphSmall } from '../../texts';
import { SimpleLineChart } from '../../graphs/SimpleLineChart';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { useSelector } from 'react-redux';

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
`;

const {
  entities: {
    portfolios: { portfolioTotalSelector },
  },
} = selectors;

export const PeriodicGain = (props) => {
  const loading = useResource(['MARKETVALUES/INDEX', 'PORTFOLIOS/INDEX']);

  const portfolioTotal = useSelector(portfolioTotalSelector);
  const portfolioChartData = usePortfolioChartData('twr', true);

  return (
    <BaseSection
      columns={12}
      style={{ display: 'flex', flexDirection: 'column', gap: SPACING_24 }}
    >
      <TextWrapper>
        <ParagraphSmall>{t('portfolio:periodicReporting:gain')}</ParagraphSmall>
        <H2>{formatBigMoney(portfolioTotal?.gain)}</H2>
      </TextWrapper>

      {loading ? (
        <CenteredActivityIndicator />
      ) : (
        <SimpleLineChart
          data={portfolioChartData.map((item) => ({
            date: item.x,
            value: item.twr,
          }))}
        />
      )}
    </BaseSection>
  );
};
