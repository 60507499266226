import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import {
  useResource,
  selectors,
  toLocaleDateString,
  formatMoney,
  getAssetClassName,
  percentage,
  t,
} from '@formue-app/core';

import { SPACING_16, SPACING_32, SPACING_8 } from '../../../constants/spacing';

import { BaseSection } from '../../common/BaseSection';
import { H4 } from '../../texts';
import { StyledCell, StyledRow, TableList } from '../../lists';
import { H3Size } from '../../../constants/text';
import { CenteredActivityIndicator } from '../../common/ActivityIndicator';
import { printCondition } from '../../../constants/media';
import { accent } from '../../../constants/colors';

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING_8};
`;

const StyledTableList = styled(TableList)`
  border-spacing: 0 !important;
`;

const LightStyledRow = styled(StyledRow)`
  &:last-child {
    span {
      font-weight: 500;
    }
  }

  &:nth-child(odd) {
    background: ${accent.sand150};
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    print-color-adjust: exact !important;
  }
`;

const LightStyledCell = styled(StyledCell)`
  padding: ${SPACING_8};

  &:first-child {
    padding-left: ${SPACING_16};
    border-radius: 0;
  }

  &:last-child {
    padding-right: ${SPACING_16};
    border-radius: 0;
  }

  span {
    font-weight: 400;
    text-align: left;
  }
`;

const StyledH4 = styled(H4)`
  @media ${printCondition} {
    font-size: ${H3Size};
    line-height: 120%;
  }
`;

const LightStyledBoldCell = styled(LightStyledCell)`
  span {
    font-weight: 500;
  }
`;

const StyledBaseSection = styled(BaseSection)`
  @media ${printCondition} {
    padding: 0 !important;
    border: none;
    background: none;
    margin-top: ${SPACING_32};

    H4 {
      padding-bottom: ${SPACING_8};
      border-bottom: 2px solid ${accent.sand150};
    }
  }
`;

const {
  entities: {
    portfolios: {
      portfolioTotalSelector,
      portfolioAssetClassesWithSubitemsSelector,
    },
    costReports: { productCostItemsSelector },
  },
} = selectors;

export const PeriodicHoldings = (props) => {
  const loading = useResource(['PORTFOLIOS/INDEX', 'COST_REPORTS/INDEX']);

  const assetClasses = useSelector(portfolioAssetClassesWithSubitemsSelector);
  const productCost = useSelector(productCostItemsSelector);
  const portfolioTotal = useSelector(portfolioTotalSelector);

  // Define the table columns
  let holdingsColumns = [
    {
      key: 'p',
      label: '',
      defaultSort: true,
      width: '20%',
      component: LightStyledCell,
    },
    {
      key: 'quantity',
      label: t('portfolio:holdingsTable:amountShort'),
      render: ({ quantity }) => formatMoney(quantity),
      width: '13%',
      type: 'number',
      component: LightStyledCell,
    },
    {
      key: 'costPrice',
      label: t('portfolio:holdingsTable:costPrice'),
      render: ({ costPrice }) => formatMoney(costPrice),
      width: '13%',
      type: 'number',
      component: LightStyledCell,
    },
    {
      key: 'priceDate',
      label: t('portfolio:holdingsTable:dateShort'),
      render: ({ priceDate }) =>
        priceDate ? toLocaleDateString(priceDate) : null,
      width: '13%',
      type: 'number',
      component: LightStyledCell,
    },
    {
      key: 'mv',
      label: t('portfolio:holdingsTable:marketValue'),
      render: ({ mv }) => formatMoney(mv),
      width: '13%',
      type: 'number',
      component: LightStyledCell,
    },
    {
      key: 'unrealizedGain',
      label: t('portfolio:holdingsTable:unrealizedReturnSinceStart'),
      render: ({ unrealizedGain }) => formatMoney(unrealizedGain),
      width: '13%',
      type: 'number',
      component: LightStyledCell,
    },
    {
      key: 'gain',
      label: t('portfolio:holdingsTable:returnInPeriod', { period: '' }),
      render: ({ gain, twr }) => `${formatMoney(gain)} (${percentage(twr)})`,
      width: '13%',
      type: 'number',
      component: LightStyledCell,
    },
  ];

  let costColumns = [
    {
      key: 'name',
      width: '70%',
      component: LightStyledCell,
    },
    {
      key: 'rate',
      label: t('portfolio:costReport:table:calculatedPercent'),
      width: '15%',
      type: 'number',
      component: LightStyledCell,
    },
    {
      key: 'amount',
      label: t('portfolio:costReport:table:calculatedAmount'),
      width: '15%',
      type: 'number',
      component: LightStyledCell,
    },
  ];

  let portfolioColumns = [
    {
      key: 'name',
      width: '40%',
      component: LightStyledBoldCell,
    },
    {
      key: 'costPrice',
      label: t('portfolio:holdingsTable:costPrice'),
      width: '13%',
      type: 'number',
      render: ({ costPrice }) => formatMoney(costPrice),
      component: LightStyledCell,
    },
    {
      key: 'mv',
      label: t('portfolio:holdingsTable:marketValue'),
      width: '13%',
      type: 'number',
      render: ({ mv }) => formatMoney(mv),
      component: LightStyledCell,
    },
    {
      key: 'unrealizedGain',
      label: t('portfolio:holdingsTable:unrealizedReturnSinceStart'),
      width: '13%',
      type: 'number',
      render: ({ unrealizedGain }) => formatMoney(unrealizedGain),
      component: LightStyledCell,
    },
    {
      key: 'gain',
      label: t('portfolio:holdingsTable:returnInPeriod', { period: '' }),
      width: '20%',
      type: 'number',
      render: ({ gain, twr }) =>
        `${formatMoney(gain)} (${percentage(twr * 100)})`,
      component: LightStyledBoldCell,
    },
  ];

  return (
    <SectionWrapper>
      {loading ? (
        <CenteredActivityIndicator />
      ) : (
        <>
          {assetClasses.map((item, index) => {
            const assetClassTitle = getAssetClassName(item.class);
            const items = [...item.subItems];

            items.push({
              p: `Totalt ${assetClassTitle}`,
              quantity: item.quantity,
              priceDate: item.priceDate,
              costPrice: item.costPrice,
              unrealizedGain: item.unrealizedGain,
              gain: item.gain,
            });

            return (
              <StyledBaseSection columns={12} key={`holdings-section-${index}`}>
                <StyledH4>{assetClassTitle}</StyledH4>
                <StyledTableList
                  id="holdingsList"
                  sortable={false}
                  columns={holdingsColumns}
                  rows={items}
                  rowComponent={LightStyledRow}
                  keyExtractor={(item, index) => `asset-class-${index}`}
                  stickyHeader={true}
                />
              </StyledBaseSection>
            );
          })}
          {/* <StyledBaseSection columns={12}>
            <StyledH4>
              {t('portfolio:periodicReporting:holdings:feesTtile')}
            </StyledH4>
            <StyledTableList
              id="productCostList"
              sortable={false}
              columns={costColumns}
              rows={productCost}
              rowComponent={LightStyledRow}
              keyExtractor={(item, index) => `cost-${index}`}
              stickyHeader={true}
            />
          </StyledBaseSection> */}
          <StyledBaseSection columns={12}>
            <StyledH4>
              {t('portfolio:periodicReporting:holdings:portfolioTtile')}
            </StyledH4>
            <StyledTableList
              id="portfolioList"
              sortable={false}
              columns={portfolioColumns}
              rows={[
                {
                  name: t(
                    'portfolio:periodicReporting:holdings:totalPortfolio'
                  ),
                  ...portfolioTotal,
                },
              ]}
              keyExtractor={(item, index) => `cost-${index}`}
              stickyHeader={true}
            />
          </StyledBaseSection>
        </>
      )}
    </SectionWrapper>
  );
};
